import React, { useState } from 'react';
import { TableRow, TableCell, Button, Table } from '@material-ui/core';
import { Search, Add, Save } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { get } from 'lodash';
import client from '../../lib/apolloClient';

const ADD_TEAM_MEMBER = gql`
  mutation AddTeamMember($teamId: ID!, $userId: ID!) {
    createTeamMember(teamId: $teamId, userId: $userId) {
      id
      user {
        name
        gross_production
        assets_under_management
        number_of_clients
        __typename
      }
      __typename
    }
  }
`;

const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      id
      name
      gross_production
      assets_under_management
      number_of_clients
    }
  }
`;

const NewTeamMember = ({ team, updateTeamMembers, classes }) => {
  const [shouldFetch, setShouldFetch] = useState(false);
  const [addTeamMember] = useMutation(ADD_TEAM_MEMBER, { client });
  const [email, setEmail] = useState('');

  const [creating, setCreating] = useState(false);
  const defaultTeamMember = {
    id: null,
    name: null,
    gross_production: null,
    assets_under_management: null,
    number_of_clients: null,
  };

  const [newTeamMember, setNewTeamMember] = useState({
    ...defaultTeamMember,
  });

  const isDuplicate = (team.teamMembers || []).some(teamMember => teamMember.user.email === email);

  if (creating) {
    return (
      <React.Fragment>
        <TableRow>
          <TableCell>

            {newTeamMember.id ? newTeamMember.name :
              (<>
                Email: <input type="text" value={email} onChange={(e) => {
                  setEmail(e.target.value);
                }} />
                <Button
                  disabled={email.length === 0 || isDuplicate}
                  onClick={() => {
                    setShouldFetch(true);
                    client.query({
                      query: GET_USER_BY_EMAIL,
                      variables: { email },
                      fetchPolicy: "network-only",
                    }).then(result => {
                      if (result.data) {
                        setNewTeamMember({ ...newTeamMember, ...result.data.getUserByEmail, notSaved: true });
                      }
                      setShouldFetch(false);
                    });
                  }
                  } >
                  <Search />
                </Button>
              </>)}
          </TableCell>
          <TableCell>
            {newTeamMember.gross_production || '-'}
          </TableCell>
          <TableCell>
            {newTeamMember.assets_under_management || '-'}
          </TableCell>
          <TableCell>
            {newTeamMember.number_of_clients || '-'}
          </TableCell>
          <TableCell>
            <Button
              disabled={!newTeamMember.notSaved}
              onClick={() => {
                addTeamMember({
                  variables: {
                    teamId: team.id,
                    userId: newTeamMember.id,
                  }
                }).then(result => {
                  setCreating(false);
                  updateTeamMembers(result.data.createTeamMember);
                  setEmail('');
                  setNewTeamMember({ ...defaultTeamMember });
                });
              }}
            ><Save /></Button>
          </TableCell>
        </TableRow >
        <TableRow>
          <TableCell colSpan={5}>
            {shouldFetch && <p className={classes.error}>Fetching...</p>}
            {isDuplicate && <p className={classes.error}>User already exists in team</p>}
          </TableCell>
        </TableRow>
      </React.Fragment>

    );
  } else {
    return (
      <TableRow>
        <TableCell>
          <Button onClick={() => setCreating(true)}><Add /> Add</Button>
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    );
  }
}

const styles = theme => ({
  error: {
    color: '#db5856',
  },
});

export default withStyles(styles)(NewTeamMember);
