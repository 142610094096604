import React from 'react';
import { Route, Switch } from 'react-router';
import DashboardContainer from '../containers/DashboardContainer';
import ManagedDashboardContainer from '../containers/ManagedDashboardContainer';
import RolodexContainer from '../containers/RolodexContainer';
import UserProfileContainer from '../containers/UserProfileContainer';
import SignUpContainer from '../containers/SignUpContainer';
import ProductMixContainer from '../containers/ProductMixContainer';
import StrengthsAndPrimaryMarketsContainer from '../containers/StrengthsAndPrimaryMarketsContainer';
import MatchPreferencesContainer from '../containers/MatchPreferencesContainer';
import ProfileEducationContainer from '../containers/ProfileEducationContainer';
import RevenueInformationContainer from '../containers/RevenueInformationContainer';
import OpportunityIndexContainer from '../containers/OpportunityIndexContainer';
import JoinContainer from '../containers/JoinContainer';
import MessagesContainer from '../containers/MessagesContainer';
import ValuationsContainer from '../containers/ValuationsContainer';
import EditPeValuationDrawer from '../components/EditPeValuationDrawer/EditPeValuationDrawer';
import EditPeValuationDrawerVal from '../components/EditPeValuationDrawer/EditPeValuationDrawerVal';
import ThankYou from '../components/CandidateSignIn/ThankYou';
import DealWizardContainer from '../containers/DealWizardContainer';
import DealSurveyContainer from '../containers/DealSurveyContainer';
import DealReviewContainer from '../containers/DealReviewContainer';
import DealQuestionsContainer from '../containers/DealQuestionsContainer';
import DealCreateContainer from '../containers/DealCreateContainer';
import TipViewDiscoverPartnersContainer from '../containers/TipViewDiscoverPartnersContainer';
import TipViewFirstValuationContainer from '../containers/TipViewFirstValuationContainer';
import TipViewUpdateProfileContainer from '../containers/TipViewUpdateProfileContainer';
import TipViewDealStructureContainer from '../containers/TipViewDealStructureContainer';
import CfaContainer from '../containers/CfaContainer';
import CfaWizardContainer from '../containers/CfaWizardContainer';
import CfaIndexContainer from '../containers/CfaIndexContainer';
import CfaStartContainer from '../containers/CfaStartContainer';
import TeamRequestForm from '../components/TeamRequestForm/TeamRequestForm';

export default (
  <Switch>
    <Route path="/dashboard/rolodex" component={RolodexContainer} />
    <Route path="/dashboard/managed" component={ManagedDashboardContainer} />
    <Route path="/dashboard/tip_view_discover_partners" component={TipViewDiscoverPartnersContainer} />
    <Route path="/dashboard/tip_view_first_valuation" component={TipViewFirstValuationContainer} />
    <Route path="/dashboard/tip_view_update_profile" component={TipViewUpdateProfileContainer} />
    <Route path="/dashboard/tip_view_deal_structure" component={TipViewDealStructureContainer} />
    <Route path="/dashboard" component={DashboardContainer} />
    <Route path="/profile/strengths" component={StrengthsAndPrimaryMarketsContainer} />
    <Route path="/profile/match_preferences" component={MatchPreferencesContainer} />
    <Route path="/profile/education" component={ProfileEducationContainer} />
    <Route path="/profile/revenue_information" component={RevenueInformationContainer} />
    <Route path="/profile/product_mix" component={ProductMixContainer} />
    <Route path="/signup" component={SignUpContainer} />
    <Route path="/opportunities/thanks" component={ThankYou} />
    <Route path="/join" component={JoinContainer} />
    <Route path="/messages" component={MessagesContainer} />
    <Route path="/valuations/valuation" component={ValuationsContainer} />
    <Route path="/valuations/pevaluation" component={EditPeValuationDrawerVal} />
    <Route path="/valuations/dcf" component={EditPeValuationDrawer} />
    <Route path="/deal/questions" component={DealQuestionsContainer} />
    <Route path="/deal/create" component={DealCreateContainer} />
    <Route path="/deal/survey" component={DealSurveyContainer} />
    <Route path="/deal/wizard" component={DealWizardContainer} />
    <Route path="/deal/:dealID/review" component={DealReviewContainer} />
    <Route path="/calculators/cfa/start" component={CfaStartContainer} />
    <Route path="/calculators/cfa/wizard" component={CfaWizardContainer} />
    <Route path="/calculators/cfa/:id" component={CfaContainer} />
    <Route path="/calculators/cfa" component={CfaIndexContainer} />
    <Route path="/team_requests/new" component={TeamRequestForm} />
  </Switch>
);
