import { connect } from 'react-redux';
import BehaviorType from '../components/BehaviorTypes/BehaviorType';
import NewFormDialog from '../components/BehaviorTypes/BehaviorTypeNewFormDialog';
import CrudTable from '../components/CrudTable/CrudTable';
import * as actions from '../actions/behaviorTypeActionCreators';

const mapStateToProps = state => {
  return ({
    ...state,
    listData      : state.behavior_types,
    title         : "Behavior Types",
    NewFormDialog,
    CrudRow       : BehaviorType,
  });
}

export default connect(mapStateToProps, actions)(CrudTable);
