import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import numeral from 'numeral';
import {withStyles} from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from 'react-intl';
import CashFlowChart from "./CashFlowChart";
import BuyerTaxNonOrdinaryBreakdown from './BuyerTaxNonOrdinaryBreakdown';
import CFABanner from './CFABanner';

const BuyerPerspective = ({result, query, index, classes, intl, lenderUrl, company}) => {
  const buyerCashFlow = get(result, `cashFlow.illustration.buyer${index}CashFlow`, []);
  
  let buyBackYear = -1;

  buyBackYear = get(result, `cashFlow.buyer${index}BreakEven`);
  
  const dollarFormat = intl.locale === 'en' ? '$0,0.00' : '0,0.00 $';
  const percentFormat = intl.locale === 'en' ? '0%' : '0 %';

  if(intl.locale == 'fr') {
    numeral.localeData().delimiters.decimal = ','
    numeral.localeData().delimiters.thousands = ' '
  }

  const showCheckSwap = get(company, 'features', []).includes('check_swap');
  const showSellerNote = get(company, 'features', []).includes('cashflow_seller_note');

  return <div className="my-properties">
    <div className="cfa_graph_buyer">
      <CashFlowChart cashFlow={buyerCashFlow} personType={`Buyer ${index}`} />
    </div>
    <CFABanner lenderUrl={lenderUrl} company={company} />

    {buyBackYear > 0 && <div>
      <div className={classes.buyBack} style={{display: 'inline-block', width: 10, height:10}}></div> * Break even year 
      </div>}
    <span className="table-responsive">
        <table className="table" >
            <thead>
              <tr>
                  <th colSpan={buyerCashFlow.length + 1}><FormattedMessage id="calculators.cfa.buyer_perspective" values={{index}}/></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                  <td><strong><FormattedMessage id="calculators.cfa.year"/></strong></td>
                  {buyerCashFlow.map((v, i) => (<td style={{textAlign: 'right'}} className={(buyBackYear === i + 1) ? classes.buyBack : ''}><strong>{i + 1}{(buyBackYear === i + 1) ? '*' : ''}</strong></td>))}
              </tr>
              <tr>
                  <td><i><FormattedMessage id="calculators.cfa.revenue_growth"/></i></td>
                  {buyerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.revenueGrowth).format(percentFormat)}</td>))}
              </tr>
              <tr>
                 <td>Attrition</td>
                  {buyerCashFlow.map((v, i) => (<td style={{textAlign: 'right'}}>
                    {i == 0 ? numeral(v.attritionRate).format(percentFormat) : ''}
                  </td>))}
              </tr>
              <tr>
                 <td><strong>Gross Revenue</strong></td>
                  {buyerCashFlow.map((v) => (<td style={{textAlign: 'right'}}><b>{numeral(v.grossRevenue).format(dollarFormat)}</b></td>))}
              </tr>
              <tr>
                <td colSpan={2}><strong>Expenses</strong></td>
              </tr>
              <tr>
                  <td>{get(result, 'company.cashflow_payout_word')} Rate</td>
                  {buyerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>
                    {numeral(query.bdRetentionRate).format(intl.locale === 'en' ? '0.0%' : '0.0 %')}
                  </td>))}
              </tr>
              <tr>
                  <td>{get(result, 'company.cashflow_payout_word')}</td>
                  {buyerCashFlow.map((v) => (<td style={{textAlign: 'right', color: 'red'}}>{numeral(v.payout).divide(-1).format(dollarFormat)}</td>))}
              </tr>
              <tr>
                 <td style={{ borderTop: '3px solid black' }}>Net Income</td>
                  {buyerCashFlow.map((v) => (<td style={{ borderTop: '3px solid black', textAlign: 'right'}}>{numeral(v.netIncome).format(dollarFormat)}</td>))}
              </tr>
              <tr>
                  <td>Branch Expenses</td>
                  {buyerCashFlow.map((v) => (<td style={{color: 'red',textAlign: 'right'}}>{numeral(v.branchExpenses).format(dollarFormat)}</td>))}
              </tr>

              <tr>
                  <td>Consulting Agreement</td>
                  {buyerCashFlow.map((v) => (<td style={{color: 'red',textAlign: 'right'}}>{numeral(v.consultingAgreement).divide(-1).format(dollarFormat)}</td>))}
              </tr>
              <tr>
                <td style={{ borderTop: '3px solid black' }}><strong>Pretax Net Income</strong></td>
                {buyerCashFlow.map((v) => (<td style={{ borderTop: '3px solid black', fontWeight: 'bold', textAlign: 'right'}}>
                  {numeral(v.pretaxNetIncome).format(dollarFormat)}
                  </td>))}
              </tr>
             <BuyerTaxNonOrdinaryBreakdown buyerCashFlow={buyerCashFlow} classes={classes} />
              <tr>
                  <td><strong><FormattedMessage id="calculators.cfa.after_tax_net_income"/></strong></td>
                  {buyerCashFlow.map((v) => (<td style={{textAlign: 'right'}}><b>{numeral(v.afterTaxNetIncome).format(dollarFormat)}</b></td>))}
              </tr>
              <tr>
                <td colSpan={2}><strong>Payments To Seller</strong></td>
              </tr> 
              <tr>
                  <td>Down Payment Expense (Cash)</td>
                  {buyerCashFlow.map((v) => (<td style={{color: 'red', textAlign: 'right'}}>{numeral(v.lumpSumPaymentOutOfPocket).divide(-1).format(dollarFormat)}</td>))}
              </tr>
              <tr>
                  <td>Earn Out Expense</td>
                  {buyerCashFlow.map((v) => (<td style={{color: 'red', textAlign: 'right'}}>{numeral(v.contingentPayment).divide(-1).format(dollarFormat)}</td>))}
              </tr>
              <tr>
                  <td><strong><FormattedMessage id="calculators.cfa.debt_service"/></strong></td>
                  <td></td>
              </tr>
              <tr>
                  <td>
                    Down Payment Note
                  </td>
                  {buyerCashFlow.map((v) => (<td style={{color: 'red', textAlign: 'right'}}>{numeral(v.downPaymentNote).divide(-1).format(dollarFormat)}</td>))}
              </tr>
              { showSellerNote && <tr>
                <td>Seller Note</td>
                {buyerCashFlow.map((v) => (<td style={{color: 'red', textAlign: 'right'}}>{numeral(v.sellerNotePayment).format(dollarFormat)}</td>))}
              </tr>}
              <tr>
                {buyerCashFlow.map((v) => (<td className={classes.gap}></td>))}
              </tr>
              <tr>
                  <td style={{ borderTop: '3px solid black' }}><strong><FormattedMessage id="calculators.cfa.free_cash_flow"/></strong></td>
                  
                  {buyerCashFlow.map((v) => {if(v.freeCashFlow > -0.01) {
                    return <td style={{ borderTop: '3px solid black', textAlign: 'right'}}>{numeral(v.freeCashFlow).format(dollarFormat)}</td>
                  } else {
                    return <td style={{ borderTop: '3px solid black', color: 'red', textAlign: 'right'}}>
                       <b>{numeral(v.freeCashFlow).format(dollarFormat)}</b>
                    </td>
                  }})}
              </tr>
              <tr>
                  <td className={classes.highlightedRow}>&nbsp;&nbsp;&nbsp;<i><FormattedMessage id="calculators.cfa.debt_service_coverage_ratio"/></i></td>
                  {buyerCashFlow.map((v) => (<td className={classes.highlightedRow} style={{textAlign: 'right'}}>{numeral(v.debtServiceCoverageRatio).format('0.00')}</td>))}
              </tr>
              <br/>
            </tbody>
          </table>
        </span>
      </div>
};

const styles = {
  highlightedRow: {
    borderTop: '3px solid black !important',
    borderBottom: '2px solid black !important',
  },
  lineTop: {
    borderTop: '3px solid black !important',
  },
  gap: {
    height: 1,
    display: 'table-row',
  },
  lineBottom: {
    borderBottom: '3px solid black !important',
  },
  buyBack: {
    backgroundColor: '#98c1d9',
  }
}

const mapStateToProps = (state, props) => {
  return {
    result: {...state.cfa.result},
    query: {...state.cfa.query},
    lenderUrl: get(state, 'notes.lender_url'),
    company: { ...get(state, 'company', {}), ...get(state, 'notes.company', {}),},
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(injectIntl(BuyerPerspective))
);