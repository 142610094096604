import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Paper, 
  TextField, 
  Button, 
  Typography,
  Box,
  InputAdornment,
} from '@material-ui/core';
import { gql, useMutation, ApolloProvider } from '@apollo/client';
import { useQuery } from '@apollo/client';
import client from '../../lib/apolloClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GENERATE_HEADLINE = gql`
  mutation GenerateHeadline {
    generateAiHeadline
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(2),
    fontSize: '14px',
    color: '#555',
    backgroundColor: '#f3f3f3',
  },
  buttonContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  tips: {
    marginTop: theme.spacing(2),
    color: theme.palette.success.main,
  },
  inputAdornment: {
   flexDirection: 'column',
  },
}));

const AIHeadlineFunctional = (props) => {
  const classes = useStyles();
  const [headline, setHeadline] = useState(props.headline || '');
  const [generateHeadline] = useMutation(GENERATE_HEADLINE, {
    onCompleted: (data) => {
      console.log('data', data);
      setHeadline(data.generateAiHeadline);
    },
    onError: (error) => {
      console.error('Error generating headline:', error);
    }
  });

  const handleGenerate = () => {
    generateHeadline();
  };

  const handleRegenerate = () => {
    generateHeadline();
  };

  return (
   <Paper className={classes.root}>
      <TextField
        className={classes.textField}
        multiline
        rows={3}
        variant="outlined"
        value={headline}
        name="user[headline]"
        onChange={(e) => setHeadline(e.target.value)}
        placeholder="Your professional headline"
        InputProps={{
          className: classes.textField,
          endAdornment: (
            <InputAdornment position="end" className={classes.inputAdornment}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<i className="fa-solid fa-robot"></i>}
                onClick={handleGenerate}
                size="small"
                className={classes.generateButton}
              >
                Generate with AI
              </Button>
            </InputAdornment>
          ),
        }}
      />
    </Paper>
  );
};

const AIHeadline = (props, context) => {
  return (
    <ApolloProvider client={client}>
      <AIHeadlineFunctional {...props} />
    </ApolloProvider>
  );
}
export default AIHeadline;
