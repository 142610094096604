import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { assign, get } from 'lodash';
import { FormControlLabel, Switch } from '@material-ui/core';
import client from '../../lib/apolloClient';

const TOGGLE_TEAM_REQUEST_ITEM = gql`
  mutation ToggleTeamRequestItem($id: ID!) {
    toggleTeamRequestItem(id: $id) {
      id
      approved
      user {
        id
        name
        gross_production
        assets_under_management
        number_of_clients
      }
    }
  }
`;

const TeamRequestItemToggle = ({ teamRequestItem, updateTeamRequestItems }) => {
  const [toggleTeamRequestItem] = useMutation(TOGGLE_TEAM_REQUEST_ITEM, { client });
  const label = teamRequestItem.approved ? 'Approved' : 'Pending';
  if(!teamRequestItem.user) {
    return <></>
  }
  return (
    <FormControlLabel
      control={
        <Switch
          disabled={teamRequestItem.onAnotherTeam}
          checked={teamRequestItem.approved}
          onChange={(e) => {
            e.stopPropagation();
            const checked = e.target.checked;
            toggleTeamRequestItem({
              variables: {
                id: teamRequestItem.id,
                approved: checked
              }
            }).then((result) => {
              teamRequestItem.approved = result?.data?.toggleTeamRequestItem?.approved;
              updateTeamRequestItems({ ...teamRequestItem });
            } );
          }}
        />
      }
      label={label}
    />
  );
}

export default TeamRequestItemToggle;