import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import * as actions from '../../actions/userActionCreators';
import client from '../../lib/apolloClient';

const UPDATE_CURRENT_USER = gql`
  mutation updateCurrentUser($updateProductionDataAutomatically: Boolean!) {
    updateCurrentUser(update_production_data_automatically: $updateProductionDataAutomatically)
  }
`;

export const AutoSynchronizingRevenueInformation = ({ user, userUpdate, currentUser }) => {
  const [updateProductionDataAutomatically] = useMutation(UPDATE_CURRENT_USER, { client: client });
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser.update_production_data_automatically !== undefined) {
      setCheckboxChecked(currentUser.update_production_data_automatically);
    }
  }, [currentUser]);

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setCheckboxChecked(isChecked);
    if (isChecked) {
      setConfirmOpen(true);
    } else {
      updateProductionDataAutomatically({
        variables: {
          updateProductionDataAutomatically: false,
        },
      })
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    setCheckboxChecked(false);
  };

  const handleConfirmSubmit = () => {
    updateProductionDataAutomatically({
      variables: {
        updateProductionDataAutomatically: true,
      },
    }).then(() => {
      setConfirmOpen(false);
      window.location.reload()
    }).catch(error => {
      console.error('Error updating production data:', error);
    });
  };

  return (
    <center>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkboxChecked}
            onChange={handleCheckboxChange}
            color="primary"
          />
        }
        label="Synchronize my production data daily"
      />
      <Dialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description">
        <DialogTitle id="confirm-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            By clicking submit your current production data will be overwritten by the daily update and you will lose any changes you’ve made to your production data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmSubmit}
            color="primary"
            autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </center>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps, actions)(AutoSynchronizingRevenueInformation);