import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const findBobMuiTheme = createMuiTheme({
  palette    : {
    primary   : {
      light        : '#f3f3f3',
      main         : '#0F66C9',
      dark         : '#2a2a2a',
      contrastText : '#fff',
    },
    secondary : {
      light        : '#f3f3f3',
      main         : '#0F66C9',
      dark         : '#2a2a2a',
      contrastText : '#000',
    },
  },
  body: {
    fontFamily: '"Helvetica Neue", "Roboto", "Arial", sans-serif',
  },
  typography : {
    htmlFontSize      : '12px',
    fontSize          : '12px',
    fontWeightLight   : 300,
    fontWeightRegular : 400,
    fontWeightMedium  : 500,
    display4          : {
      fontSize      : '14px',
      fontWeight    : 300,
      fontFamily    : '"Helvetica Neue", "Roboto", "Arial", sans-serif',
      letterSpacing : '-.04em',
      lineHeight    : '1.14286em',
      marginLeft    : '-.06em',
    },
    display3          : {
      fontSize      : '14px',
      fontWeight    : 400,
      fontFamily    : '"Helvetica Neue", "Roboto", "Arial", sans-serif',
      letterSpacing : '-.02em',
      lineHeight    : '1.30357em',
      marginLeft    : '-.04em',
    },
    display2          : {
      fontSize   : '14px',
      fontWeight : 400,
      fontFamily : '"Helvetica Neue", "Roboto", "Arial", sans-serif',
      lineHeight : '1.06667em',
      marginLeft : '-.04em',
    },
    display1          : {
      fontSize   : '14px',
      fontWeight : 400,
      fontFamily : '"Helvetica Neue", "Roboto", "Arial", sans-serif',
      lineHeight : '1.20588em',
      marginLeft : '-.04em',
    },
    headline          : {
      fontSize   : '14px',
      fontWeight : 400,
      fontFamily : '"Helvetica Neue", "Roboto", "Arial", sans-serif',
      lineHeight : '1.35417em',
    },
    title             : {
      fontSize   : '14px',
      fontWeight : 500,
      fontFamily : '"Helvetica Neue", "Roboto", "Arial", sans-serif',
      lineHeight : '1.16667em',
    },
    subheading        : {
      fontSize   : '12px',
      fontWeight : 400,
      fontFamily : '"Helvetica Neue", "Roboto", "Arial", sans-serif',
      lineHeight : '1.5em',
    },
    body2             : {
      fontSize   : '12px',
      fontWeight : 500,
      fontFamily : '"Helvetica Neue", "Roboto", "Arial", sans-serif',
      lineHeight : '1.71429em',
    },
    body1             : {
      fontSize   : '12px',
      fontWeight : 400,
      fontFamily : '"Helvetica Neue", "Roboto", "Arial", sans-serif',
      lineHeight : '1.46429em',
    },
    caption           : {
      fontSize   : '12px',
      fontWeight : 400,
      fontFamily : '"Helvetica Neue", "Roboto", "Arial", sans-serif',
      lineHeight : '1.375em',
    },
    button            : {
      fontSize      : '12px',
      textTransform : 'uppercase',
      fontWeight    : 500,
      fontFamily    : '"Helvetica Neue", "Roboto", "Arial", sans-serif',
    },
  },
});

export default findBobMuiTheme;
