import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import { Box, Tabs, Tab, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TabPanel from '../TabPanel/TabPanel';
import Grades from './Grades';
import PrintableGrades from './PrintableGrades';
import BuyerPerspective from './BuyerPerspective';
import BuyerNonOrdinaryPerspective from './BuyerNonOrdinaryPerspective';
import SellerPerspective from './SellerPerspective';
import * as cfaActionCreators from '../../actions/cfaActionCreators';
import { get } from 'lodash';
import qs from 'query-string';
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import SaveCfaButton from "./SaveCfaButton";
import EditCfa from "./EditCfa";
import CfaList from "./CfaList";
import { FormattedMessage } from "react-intl";
import Disclaimer from './CfaWizard/Disclaimer';

const CfaComponent = ({ updateCfaQuery, result, query, classes, motivation, company }) => {
  const [value, setValue] = React.useState(motivation === 'succession' ? 0 : 1);
  const [printing, setPrinting] = React.useState(false);
  const [printedOnLoad, setPrintedOnLoad] = React.useState(false);
  const exportRef = useRef();
  const cf = get(result, 'cashFlow', {});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const print = () => {
    const opt = {
        pagebreak: { 
            mode: ['avoid-all'], 
            before: '.page_break', 
            after: '.page_break2', 
            avoid: '.printable' 
        },
        jsPDF: { 
            unit: 'in', 
            format: 'letter', 
            orientation: 'landscape', 
            quality: 2.0
        },
        margin: [0.5, 0.5, 0.5, 0.5],
        html2canvas: { 
            scale: 2,
            useCORS: true 
        }
    };

    const graphElementSeller = document.querySelector('.cfa_graph');
    const graphElementBuyer = document.querySelector('.cfa_graph_buyer');
    const cfaReferral = document.querySelector('.cfa_referral');
    const cfaReferralBuyer = document.querySelector('.cfa_referral_buyer');
    const tableElements = document.querySelectorAll('.printable');
    const resetCSS = document.createElement('style');
    resetCSS.innerHTML = `
        * {
            margin: 0 !important;
            padding: 0 !important;
            font-family: Arial, sans-serif !important;
            line-height: 1 !important;
        }
        h1, h2, h3 {
            color: #333;
            margin: 0;
            font-weight: bold;
        }
        .printable {
            display: block;
            margin: 0;
            padding: 0.2in;
            border: 1px solid #ccc;
        }
        .cfa_buyer_seller_summaries {
            display: flex;
            justify-content: space-between;
            padding: 0;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 0;
            margin-bottom: 10px;
            padding: 0;
        }
        th, td {
            border: 1px solid #ddd;
            padding: 0;
            text-align: left;
        }
        th {
            background-color: #f5f5f5;
            font-weight: bold;
        }
        cfa_referral, cfa_referral_buyer {
            display: none;
        }
    `;
    document.head.appendChild(resetCSS);

    const elementsToHide = [graphElementSeller, graphElementBuyer, cfaReferral, cfaReferralBuyer];
    const originalStyles = elementsToHide.map(element => (element ? element.style.cssText : ''));

    elementsToHide.forEach(element => {
        if (element) {
            element.style.display = 'none';
            element.style.height = '0';
            element.style.width = '0';
        }
    });

    tableElements.forEach((table) => {
        table.style.display = 'block';
        table.style.marginTop = '0';
        table.style.marginBottom = '0.0in';
        table.style.padding = '0';
        table.style.lineHeight = '1';
    });

    const worker = html2pdf().set(opt).from(exportRef.current).save("Cash_Flow_Analysis.pdf");

    worker.then(() => {
        elementsToHide.forEach((element, index) => {
            if (element) {
                element.style.cssText = originalStyles[index];
            }
        });
        document.head.removeChild(resetCSS);
    });

    setPrinting(false);
};


  const params = qs.parse(location.search);

  if (!printedOnLoad && params.print) {
    setPrintedOnLoad(true);
    setPrinting(true);
    setTimeout(print, 400);
  }

  const showOrdinaryTaxes = get(company, 'features', []).includes('cash_flow_ordinary_income_tax_rates');

  return (<React.Fragment>
    <h1>
      <FormattedMessage id="calculators.cfa.title" values={{ cash_flow_analysis_word: get(company, 'company.data.cash_flow_analysis_word') }} />
      <div className={classes.buttonBox}>
        <Button variant="contained" color="primary" className={classes.downloadButton} style={{ margin: 10 }} onClick={() => {
          setPrinting(true);
          setTimeout(print, 400);
        }}><FormattedMessage id="calculators.cfa.download_pdf" /></Button>
        <EditCfa />
      </div>
    </h1>
    <Grid container spacing={2}>
      
      <Grid item xs={12}>
        <div style={{ display: 'flex' }} className="cfa_buyer_seller_summaries">
          <Grades handleClick={handleChange} value={value} />
        </div>
        <div style={{ clear: 'both' }}>
          <Box sx={{ width: '100%' }}>
            <div>
              {!printing ?
                <div>
                  <TabPanel value={value} index={0}>
                    <SellerPerspective />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {showOrdinaryTaxes ? <BuyerPerspective index={1} /> : <BuyerNonOrdinaryPerspective index={1} />}
                  </TabPanel>
                  {get(result, 'cashFlow.numberOfBuyers', 0) > 1 && <TabPanel value={value} index={2}>
                    {showOrdinaryTaxes ? <BuyerPerspective index={2} /> : <BuyerNonOrdinaryPerspective index={2} />}
                  </TabPanel>}
                  <br />
                  <br />
                  <Disclaimer />
                </div>
                : 
                <div>
                <span ref={exportRef} style={{ fontSize: 10, margin: '0 0.2in', padding: 0 }}>
                  <PrintableGrades />
                  <br />
                  <span style={{ display: 'block', margin: 0, padding: 0 }} className="printable">
                    <SellerPerspective />
                  </span> 
                  <span style={{ display: 'block', margin: 0, padding: 0 }} className="printable">
                    {showOrdinaryTaxes ? <BuyerPerspective index={1} /> : <BuyerNonOrdinaryPerspective index={1} />}
                  </span>         
                  {get(result, 'cashFlow.numberOfBuyers', 0) > 1 && (
                    <span style={{ display: 'block', margin: 0, padding: 0 }} className="printable">
                      {showOrdinaryTaxes ? <BuyerPerspective index={2} /> : <BuyerNonOrdinaryPerspective index={2} />}
                    </span>
                  )}         
                  <span style={{ display: 'block', margin: 0, padding: 0 }} className="printable">
                    <br />
                    <br />
                    <Disclaimer />
                  </span>
                </span>
              </div>
              
              }
            </div>
          </Box>
        </div>
      </Grid>
    </Grid>
  </React.Fragment>)
};

const styles = {
  tab: {
    '& .MuiBox-root': {
      padding: '0px',
    },
    padding: '0px',
  },
  flexContainer: {
    width: '100%',
    justifyContent: 'space-between',
  },
  contentButton: {
    height: 'auto',
    padding: 0,
    border: 'none',
    background: 'none',
  },
  viewAll: {
    position: 'absolute',
    bottom: '25px',
    right: '25%',
    left: '50%',
    marginLeft: '-150px',
    color: '#0052CC',
  },
  buttonBox: { 
    display: 'flex',
    justifyContent: 'stretch',
    position: 'absolute',
    right: '0',
    top: '3px',
    height: '56px',
    alignItems: 'center',
    width: '240px',
  },
  downloadButton: {
    height: '30px',
    width: '138px',
  }
};

const mapStateToProps = (state, props) => {
  return {
    result: { ...state.cfa.result },
    query: { ...state.cfa.query },
    company: state.company,
  };
};

export default withStyles(styles)(connect(mapStateToProps, { ...cfaActionCreators, })(
  CfaComponent
));