import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SimpleInputField from '../SimpleTextField/SimpleTextField';
import * as EmailValidator from 'email-validator';
import { injectIntl } from 'react-intl';

const styles = theme => ({
  root    : {
    flexGrow : 1,
    width    : '100%',
    margin   : 12,
  },
  paper   : {
    padding : 12,
  },
  control : {
    padding : theme.spacing.unit * 2,
  },
  title   : {
    fontSize : 24,
    margin   : 12,
  },
  button  : {
    margin   : theme.spacing.unit,
    fontSize : '24px',
  },
});

const BehaviorType = ({ object, action, deleteAction, updateAction, classes, intl }) => (
  <TableRow>
    <TableCell>
      <SimpleInputField
        id="type_name"
        autoFocus
        required
        label={intl.formatMessage({ id: 'shared.name' })}
        data={object}
        action={action}
        fullWidth
      />
    </TableCell>
    <TableCell>
      <SimpleInputField
        id="score"
        type='number'
        autoFocus
        required
        label={"Score"}
        data={object}
        action={action}
        fullWidth
      />
    </TableCell>
    <TableCell>
      <IconButton
        color="primary"
        className={classes.button}
        aria-label="Save"
        disabled={!object.type_name || !object.score || !object.modified}
        onClick={() => {
          updateAction(object);
        }}>
        <Icon>save</Icon>
      </IconButton>
      <IconButton className={classes.button} aria-label="Delete">
        <DeleteIcon
          onClick={() => {
            deleteAction(object);
          }}
        />
      </IconButton>
    </TableCell>
  </TableRow>
);

const AdvisorLicenseWithStyles = withStyles(styles)(BehaviorType);
export default injectIntl(AdvisorLicenseWithStyles);
