import ReactOnRails from 'react-on-rails';

import CompanyAdminApp from '../bundles/FindBob/startup/CompanyAdminApp';
import UserRating from '../bundles/FindBob/containers/UserRatingContainer';
import SocialButtons from '../bundles/FindBob/containers/SocialButtonsContainer';
import FindBobApp from '../bundles/FindBob/startup/FindBobApp';
import AdminNavigation from '../bundles/FindBob/containers/NavigationDrawerContainer';
import CurrentPipelineActivities from '../bundles/FindBob/components/Admin/CurrentPipelineActivities';
import BrowserDetectionBanner from '../bundles/FindBob/components/BrowserDetectionBanner/BrowserDetectionBanner';
import AnalyticsPipelineSummaryReportContainer from '../bundles/FindBob/containers/Reports/AnalyticsPipelineSummaryReportContainer';
import MyOpenTasksContainer from '../bundles/FindBob/containers/MyOpenTasksContainer';
import MatchProfileContainer from '../bundles/FindBob/containers/MatchProfileContainer';
import RevenueContainer from '../bundles/FindBob/containers/RevenueContainer';
import SuggestedUsersPanel from '../bundles/FindBob/components/SuggestedUsersPanel/SuggestedUsersPanel';
import SuggestedDealsPanel from '../bundles/FindBob/components/SuggestedDealsPanel/SuggestedDealsPanel';
import Snackbars from '../bundles/FindBob/components/Snackbars/Snackbars';
import CarouselContainer from '../bundles/FindBob/containers/CarouselContainer';
import ChatWidget from '../bundles/FindBob/containers/ChatWidgetContainer';
import Spotlight from '../bundles/FindBob/containers/SpotlightContainer';
import FileTab from '../bundles/FindBob/containers/FileTabContainer';
import TrendingBobsContainer from '../bundles/FindBob/containers/TrendingBobsContainer';
import ProfileDrawerContainer from '../bundles/FindBob/containers/ProfileDrawerContainer';
import NotificationsMenuContainer from '../bundles/FindBob/containers/NotificationsMenuContainer';
import LoansOtpCalculator from '../bundles/FindBob/containers/LoansOtpCalculatorContainer';
import LoansRepaymentCalculator from '../bundles/FindBob/containers/LoansRepaymentCalculatorContainer';
import TransitionGoalSelector from '../bundles/FindBob/containers/TransitionGoalSelectorContainer';
import UserProfileContainer from '../bundles/FindBob/containers/UserProfileContainer';
import ManagerConfirmation from '../bundles/FindBob/components/ManagerConfirmation/ManagerConfirmation';
import FBSelect from '../bundles/FindBob/components/FBSelect/FBSelect';
import CloseDealButton from '../bundles/FindBob/components/CloseDealButton/CloseDealButton';
import DCFList from "../bundles/FindBob/components/DCFList/DCFList";
import DashboardMarketplaceTicker from '../bundles/FindBob/components/DashboardMarketplaceTicker/DashboardMarketplaceTicker';
import SuccessionReadinessWidgetContainer from '../bundles/FindBob/containers/SuccessionReadinessWidgetContainer';
import PartialSalesContainer from '../bundles/FindBob/containers/PartialSalesContainer';
import SupportDrawerContainer from '../bundles/FindBob/containers/SupportDrawerContainer';
import LeadDrawerContainer from '../bundles/FindBob/containers/LeadDrawerContainer';
import ValuationWidget from '../bundles/FindBob/components/ValuationWidget/ValuationWidget';
import AIHeadline from '../bundles/FindBob/components/AiHeadline/AiHeadline';
import AIBio from '../bundles/FindBob/components/AiBio/AiBio';

import 'babel-polyfill';

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(search, pos) {
        return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
    };
}

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
    CompanyAdminApp,
    UserRating,
    SocialButtons,
    FindBobApp,
    AdminNavigation,
    CurrentPipelineActivities,
    AnalyticsPipelineSummaryReportContainer,
    MyOpenTasksContainer,
    MatchProfileContainer,
    RevenueContainer,
    SuggestedUsersPanel,
    SuggestedDealsPanel,
    Snackbars,
    CarouselContainer,
    ChatWidget,
    Spotlight,
    FileTab,
    TrendingBobsContainer,
    ProfileDrawerContainer,
    NotificationsMenuContainer,
    LoansOtpCalculator,
    LoansRepaymentCalculator,
    TransitionGoalSelector,
    UserProfileContainer,
    BrowserDetectionBanner,
    ManagerConfirmation,
    FBSelect,
    CloseDealButton,
    DCFList,
    DashboardMarketplaceTicker,
    SuccessionReadinessWidgetContainer,
    PartialSalesContainer,
    SupportDrawerContainer,
    LeadDrawerContainer,
    ValuationWidget,
    AIHeadline,
    AIBio,
});