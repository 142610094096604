import React, { useState } from 'react';
import { TableRow, TableCell, TextField } from '@material-ui/core';
import { Check, Clear, Save, Edit } from '@material-ui/icons'
import TeamRequestItemToggle from './TeamRequstItemToggle';
import gql from 'graphql-tag';
import client from '../../lib/apolloClient';
import { useMutation } from '@apollo/client';
import { result } from 'lodash';
import { Tab } from 'bootstrap';

const UPDATE_TEAM_REQUEST_ITEM = gql`
  mutation UpdateTeamRequestItem($id: ID!, $email: String!) {
    updateTeamRequestItem(id: $id, email: $email) {
      id
      email
      userDisapproved
      onAnotherTeam
      user {
        id
        name
        gross_production
        assets_under_management
        number_of_clients
        inventory_user {
          id
          catestrophic_plan_on_file
          __typename
        }
      }
    }
  }
`;

const TeamRequestItemRow = ({ item, updateTeamRequestItems }) => {
  const [edit, setEdit] = useState(false);
  const [email, setEmail] = useState(item.email);
  const [updateTeamRequestItem] = useMutation(UPDATE_TEAM_REQUEST_ITEM, { client });

  return <TableRow key={item.id}>
          <TableCell>
            {item.user ? <Check style={{color: 'green'}}/> :<Clear style={{color: 'red'}} />} 
          </TableCell>
          <TableCell>{edit ? <input type="text" value={email} onChange={e => {setEmail(e.target.value)}}/> : item.email}</TableCell>
          <TableCell>
            <TeamRequestItemToggle teamRequestItem={item} updateTeamRequestItems={updateTeamRequestItems} />
          </TableCell>
          <TableCell>
            {item.user?.inventory_user?.catestrophic_plan_on_file ? <Check style={{color: 'green'}}/> : <Clear style={{color: 'red'}} />}
          </TableCell> 
          <TableCell>{item.userDisapproved ? <span style={{color:'red'}}>OPTED OUT</span> : '-'}</TableCell>
          <TableCell>{item.onAnotherTeam ? <span style={{color:'red'}}>ON ANOTHER TEAM</span> : '-'}</TableCell>
          <TableCell>
            {!item.approved && <>
              {edit ? 
                <Save onClick={
                  () => {
                    updateTeamRequestItem({ variables: { id: item.id, email: email } }).then(result => {
                      setEdit(false);
                      updateTeamRequestItems(result.data.updateTeamRequestItem);
                    }
                    );
                  }
                }/> 
                :
                <Edit onClick={() => setEdit(true)} />
              }
            </>}
          </TableCell>
        </TableRow>;
};

export default TeamRequestItemRow;
