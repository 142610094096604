import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import moment from 'moment';
import numeral from 'numeral';
import classNames from 'classnames';
import { isEmpty, get, debounce } from 'lodash';
import { graphql } from 'react-apollo';
import { createMuiTheme } from '@material-ui/core/styles';
import { updatePipelineCardMutation } from './PipelineQueries';
import FindbobDatePicker from '../FindbobDatePicker/FindbobDatePicker';
import InventoryUserSearchField from '../../containers/InventoryUserSearchFieldContainer';
import ManagerSearchField from '../../containers/ManagerSearchFieldContainer';
import PipelineActivityTab from '../../containers/PipelineActivityTabContainer';
import PipelineTaskTab from '../../containers/PipelineTaskTabContainer';
import CustomDollarFormat from '../CustomDollarFormat/CustomDollarFormat';
import FileTab from '../FileTab/FileTab';
import DealStepTab from './DealStepTab';
import ContinuityStepTab, { DownloadCatPlan } from './ContinuityStepTab';
import CandidateTab from './CandidateTab';
import ConversationContainer from '../../containers/ConversationContainer';
import SuccessionReadinessTab from './SuccessionReadinessTab';
import DownloadCatPlanButton from '../InventoryUserDrawer/DownloadCatPlanButton';
import CashFlowAnalysisWidgit from '../InventoryUserDrawer/CashFlowAnalysisWidgit';
import { tr } from 'date-fns/locale';

class PipelineCardDrawer extends Component {
  constructor (props) {
    super(props);
    const searchParams = new URLSearchParams(document.location.search);
    const open_tab = searchParams.get('open_tab');
    if (open_tab) {
      this.state = { card: {}, value: open_tab};
    }
    else {
      this.state = { card: {}, value: 0};
    }
  }

  muiTheme = createMuiTheme({
    stepper : {
      iconColor : 'green', // or logic to change color
    },
  });

  componentWillReceiveProps (nextProps) {
    const { pipelineCard } = nextProps;
    const { card } = this.state;

    if (get(pipelineCard, 'id') !== get(card, 'id')) {
      this.setState({ card: pipelineCard });
    }
  }

  onSave = debounce((card, reload) => {
    const { mutate, updateCardSingleCard } = this.props;
    const pipeline_id = get(card, 'pipeline.id');
    const pipeline_stage_id = get(card, 'pipeline_stage.id');
    const owner_id = get(card, 'owner.id');
    const primary_agent_id = get(card, 'primary_agent.id');

    const crd = {
      ...card,
      inventory_users   : card.inventory_users.map(i => get(i, 'id', i)),
      owner_id,
      primary_agent_id,
      pipeline_id,
      pipeline_stage_id,
    };
    const variables = { ...crd };

    mutate({ variables })
      .then(() => {
        updateCardSingleCard(variables);
        if (reload) {
          window.location.href = `/admin/pipelines/${pipeline_id}`;
        }
        // eslint-disable-next-line no-console
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  }, 300);

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  renderAdditionalTab = pipelineName => {
    const { classes } = this.props;
    if (pipelineName === 'Deal Tracking') {
      return <Tab className={classes.tabStyle} label="Deal Info" />;
    }
    if (pipelineName === 'Business Continuity Builder') {
      return <Tab className={classes.tabStyle} label="Continuity Info" />;
    }
    if (pipelineName === 'Recruitment') {
      return <Tab className={classes.tabStyle} label="Candidate Details" />;
    }
    if (pipelineName === 'Succession Readiness') {
      return <Tab className={classes.tabStyle} label="Succession Readiness" />;
    }
    return <span />;
  };

  renderAdditionalTabInfo = pipelineName => {
    const { pipelineCard, pipelineList, updateCard, pipeline } = this.props;
    const dealTrackingPipeLineId = pipelineList.filter(p => p.name === 'Deal Tracking')[0].id;

    const convoId = get(pipelineCard, 'deal_interest.conversation_id', false);

    if (pipelineName === 'Deal Tracking') {
      return (<div>
        <DealStepTab card={pipelineCard} />
          { convoId && <div>
              <div style={{ margin: 10, fontSize: 10, fontWeight: 'bold', display: 'flex' }}>View Conversation: 
                <span style={{ marginTop: -6, paddingLeft: 4,}}>
                  <ConversationContainer client={get(this.props, 'result.client', {})} convoId={convoId} />
                </span>
              </div>
            </div>}
        </div>);
    }
    if (pipelineName === 'Business Continuity Builder') {
      return (
        <ContinuityStepTab
          updateCard={updateCard}
          card={pipelineCard}
          dealTrackingPipeLineId={dealTrackingPipeLineId}
          pipeline={pipeline}
        />
      );
    }
    if (pipelineName === 'Recruitment') {
      return <CandidateTab card={pipelineCard} />;
    }
    if (pipelineName === 'Succession Readiness') {
      return <SuccessionReadinessTab card={pipelineCard} />;
    }
    return <span />;
  };

  render () {
    const { onClose, classes, updateCard, pipelineList, pipelineCard } = this.props;
    const { card, value } = this.state;

    let rjCaseVerificationFields = false;
    if (pipelineCard && pipelineCard.inventory_users && pipelineCard.inventory_users[0]) {
      if (get(pipelineCard, 'inventory_users[0].user.company.feature_types', []).includes('rj_case_verification_fields')) {
        rjCaseVerificationFields = true;
      }
    }

    let advisorLicensesList = '';
    if (pipelineCard && pipelineCard.inventory_users && pipelineCard.inventory_users[0]) {
      const advisorLicenses = get(pipelineCard, 'inventory_users[0].user.advisor_licenses', []);
      if (advisorLicenses && Array.isArray(advisorLicenses)) {
        advisorLicensesList = advisorLicenses.join(', ');
      }
    }

    let directorNamesList = '';
    if (pipelineCard && pipelineCard.inventory_users && pipelineCard.inventory_users[0]) {
      const directorNames = [
        get(pipelineCard, 'inventory_users[0].divisional_director_name', null),
        get(pipelineCard, 'inventory_users[0].asst_divisional_director1_name', null),
        get(pipelineCard, 'inventory_users[0].asst_divisional_director2_name', null)
      ];
      if (directorNames.every(name => name == null || name === '')) {
        directorNamesList = 'No Directors';
      } else {
        directorNamesList = directorNames.join(', ');
      }
    }
    const inventoryUser = get(pipelineCard, 'inventory_users[0]', null);
    const documents = get(inventoryUser, 'documents', []);
    const customerUserWorkingDocs = get(pipelineCard, 'primary_agent.company.feature_types', []).includes('customer_user_working_docs');
    const pipelineCardOwner = get(pipelineCard, 'owner', null);
    const workingDocEnumNumber = 1;
    
    if (!card.id) return <span />;
    const owner = get(card, 'owner', null);
    if (owner) {
      owner.label = owner.name;
    }
    const pipelineName = get(pipelineCard, 'pipeline.name', '');

    const primary_agent = get(card, 'primary_agent', null);
    if (primary_agent) {
      primary_agent.label = primary_agent.name;
      primary_agent.value = primary_agent.id;
    }

    let inventoryUsers = get(card, 'inventory_users', null);
    if (inventoryUsers) {
      inventoryUsers = inventoryUsers.map(i => ({ ...i, label: i.name }));
    }

    const formatCurrency = (value) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);
    };

    const partialSalesPropertyCasualty = pipelineCard.primary_agent.company?.feature_types?.includes('partial_sales_property_casualty') || false;

    return (
      <Drawer
        open={!isEmpty(card)}
        variant="persistent"
        anchor="right"
        classes={{
          paper : classes.drawerPaper,
        }}>
        <Button onClick={onClose}>Close</Button>
        <h3 className={classes.container}>
          <Avatar className={classes.avatar}>$</Avatar>
          <div style={{ float: 'left' }}>
            <span className={classes.cardHeader}>{decodeURI(card.name || '')}</span>
            <br />
            <span className={classes.subHeader}>
              {get(card, 'primary_agent.label')} / {numeral(card.value).format('$0,')}
            </span>
          </div>
        </h3>
        <Tabs
          value={value}
          onChange={this.handleChange}
          centered
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth">
          <Tab className={classes.tabStyle} label="Details" />
          <Tab className={classes.tabStyle} label="Activity" />
          <Tab className={classes.tabStyle} label="Related" />
          {this.renderAdditionalTab(pipelineName)}
          {customerUserWorkingDocs && pipelineCardOwner &&
            <Tab className={classes.tabStyle} label="Working Docs" />
          }
          {pipelineName === 'Deal Tracking' && <Tab className={classes.tabStyle} label="Data Room" />}
        </Tabs>
        <Divider />
        <SwipeableViews axis="x" index={value} onChangeIndex={this.handleChangeIndex}>
          <div className={classes.tabContainer}>
            <div style={{ fontSize: 10, fontWeight: 'bold' }}>Name: </div>
            <TextField
              id="name"
              type="text"
              className={classes.inputField}
              value={decodeURI(card.name)}
              placeholder="Add Name"
              onChange={e => {
                const crd = { ...card, name: encodeURI(e.target.value) };
                this.setState({ card: crd });
              }}
              onBlur={() => {
                this.onSave(card);
                updateCard(card);
              }}
              fullWidth
            />
            <br />
            <br />
            <div style={{ fontSize: 10, fontWeight: 'bold' }}>Value: </div>
            <TextField
              className={classes.inputField}
              value={card.value}
              placeholder="Add Value"
              id="formatted-numberformat-input"
              InputProps={{
                inputComponent : CustomDollarFormat,
              }}
              onChange={e => {
                const crd = { ...card, value: parseFloat(e.target.value) };

                this.setState({ card: crd });
              }}
              onBlur={() => {
                this.onSave(card);
                updateCard(card);
              }}
            />
            <br />
            <br />
            <div style={{ fontSize: 10, fontWeight: 'bold' }}>Pipelines: </div>
            <Select
              value={get(card, 'pipeline.id')}
              className={classes.inputField}
              onChange={e => {
                const pipeline_id = e.target.value;
                if (pipeline_id) {
                  const stage = pipelineList.find(p => p.id === pipeline_id).pipeline_stages[0];
                  const crd = { ...card, pipeline: { id: pipeline_id }, pipeline_stage: stage };
                  this.onSave(crd, true);
                }
              }}>
              {pipelineList.map(p => (
                <MenuItem key={`pl_${p.id}`} value={p.id}>
                  {decodeURI(p.name)}
                </MenuItem>
              ))}
            </Select>
            <br />
            <br />
            <div style={{ fontSize: 10, fontWeight: 'bold' }}>Description: </div>
            <TextField
              id="description"
              type="text"
              multiline
              value={decodeURI(card.description)}
              placeholder="Add Description"
              className={classes.inputField}
              onChange={e => {
                const crd = { ...card, description: encodeURI(e.target.value) };
                this.setState({ card: crd });
              }}
              onBlur={() => {
                this.onSave(card);
                updateCard(card);
              }}
              fullWidth
            />
            <br />
            <br />
            <div style={{ fontSize: 10, fontWeight: 'bold' }}>Close Date: </div>
            <FindbobDatePicker
              getDate={date => {
                const crd = { ...card, close_date: date };
                this.onSave(crd);
                updateCard(crd);
                this.setState({ card: crd });
              }}
              date={card.close_date}
              maxYear={moment().year() + 20}
              minYear={moment().year()}
              ascYears
            />
            <br />
            <div style={{ fontSize: 10, fontWeight: 'bold' }}>Owner: </div>
            <ManagerSearchField
              value={{ ...owner, value: get(owner, 'id') }}
              onChange={v => {
                const crd = { ...card, owner: v };
                this.onSave(crd);
                updateCard(crd);
                this.setState({ card: crd });
              }}
            />
            <br />
            <div style={{ fontSize: 10, fontWeight: 'bold' }}>Primary Agent: </div>
            <InventoryUserSearchField
              value={primary_agent}
              openUserCard
              onChange={v => {
                const crd = { ...card, primary_agent: v };
                const pa = crd.inventory_users.find(i => i.id === v.id);
                if (!pa) {
                  crd.inventory_users.push(v);
                }

                this.onSave(crd);
                updateCard(crd);
                this.setState({ card: crd });
              }}
            />           
            <br />
            {rjCaseVerificationFields && (
              <div>
                <br />
                <div style={{ fontSize: 10, fontWeight: 'bold' }}>Payout: </div>
                <TextField
                  id="branch_net_payout"
                  type="text"
                  multiline
                  value={`${parseFloat(decodeURI(pipelineCard.inventory_users[0].user.branch_net_payout)) * 100}%`}
                  placeholder="No Payout"
                  className={classes.inputField}
                  disabled
                  fullWidth
                />
                <br />
                <br />
                <div style={{ fontSize: 10, fontWeight: 'bold' }}>Directors: </div>
                <TextField
                  id="director_names_list"
                  type="text"
                  multiline
                  value={directorNamesList}
                  placeholder="No Directors"
                  className={classes.inputField}
                  disabled
                  fullWidth
                />
                <br />
                <br />
                <div style={{ fontSize: 10, fontWeight: 'bold' }}>Licenses: </div>
                <TextField
                  id="advisor_licenses_list"
                  type="text"
                  multiline
                  value={advisorLicensesList}
                  placeholder="No Advisor Licenses"
                  className={classes.inputField}
                  disabled
                  fullWidth
                />
                <br />
              </div>
            )}
            <br />
            <div style={{ fontSize: 10, fontWeight: 'bold' }}>Status: </div>
            <TextField
              id="standard-status"
              select
              fullWidth
              className={classes.inputField}
              value={card.status}
              onChange={e => {
                const crd = { ...card, status: e.target.value };
                this.setState({ card: crd });
                this.onSave(crd);
                updateCard(crd);
              }}
              InputLabelProps={{
                shrink : true,
              }}
              margin="normal">
              {[
                { value: 'open', label: 'Open' },
                { value: 'won', label: 'Won' },
                { value: 'lost', label: 'Lost' },
                { value: 'abandoned', label: 'Abandoned' },
              ].map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <br />
            <div style={{ fontSize: 10, fontWeight: 'bold' }}>Priority: </div>
            <TextField
              id="standard-priority"
              select
              fullWidth
              className={classes.inputField}
              value={card.priority}
              onChange={e => {
                const crd = { ...card, priority: e.target.value };
                this.setState({ card: crd });
                this.onSave(crd);
                updateCard(crd);
              }}
              InputLabelProps={{
                shrink : true,
              }}
              margin="normal">
              {[
                { value: 'no', label: 'None' },
                { value: 'low', label: 'Low' },
                { value: 'medium', label: 'Medium' },
                { value: 'high', label: 'High' },
              ].map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <br />
            <div style={{ fontSize: 10, fontWeight: 'bold' }}>Visibility: </div>
            <TextField
              id="standard-visibility"
              select
              fullWidth
              className={classes.inputField}
              value={card.visibility}
              onChange={e => {
                const crd = { ...card, visibility: e.target.value };
                this.setState({ card: crd });
                this.onSave(crd);
                updateCard(crd);
              }}
              InputLabelProps={{
                shrink : true,
              }}
              margin="normal">
              {[
                { value: 'public_visibility', label: 'Public' },
                { value: 'private_visibility', label: 'Private' },
              ].map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
           
          </div>
          <div className={classNames([ classes.tabContainer, classes.dark ])}>
            <PipelineActivityTab pipelineCardId={card.id} />
            
            {card.partial_sale_listing && (
              <Card className='content-card'>
                <h5 style={{ paddingLeft: '7px' }}> {get(primary_agent, 'company.partial_sale_name', 'Partial Sale')} Details</h5>
                <hr style={{ margin: '10px 0' }} />            
                <h6 style={{ paddingLeft: '7px' }}>{partialSalesPropertyCasualty ? 'Premium' : 'AUM'}: {formatCurrency(get(card, 'partial_sale_listing.assets_under_management'))}</h6>
                <h6 style={{ paddingLeft: '7px' }}>Clients: {get(card, 'partial_sale_listing.number_of_clients')}</h6>
                <h6 style={{ paddingLeft: '7px' }}>{partialSalesPropertyCasualty ? 'Recurring Revenue' : 'Fee Based Revenue'}: {formatCurrency(get(card, 'partial_sale_listing.fee_based_revenue'))}</h6>
                {!partialSalesPropertyCasualty && (
                  <div>
                    <h6 style={{ paddingLeft: '7px' }}>Trails Revenue: {formatCurrency(get(card, 'partial_sale_listing.trails_revenue'))}</h6>
                    <h6 style={{ paddingLeft: '7px' }}>Direct Revenue: {formatCurrency(get(card, 'partial_sale_listing.direct_revenue'))}</h6>
                  </div>
                )}
                <h6 style={{ paddingLeft: '7px' }}>{partialSalesPropertyCasualty ? 'Non-Recurring Revenue' : 'Transactional Revenue'}: {formatCurrency(get(card, 'partial_sale_listing.commission_revenue'))}</h6>
            </Card>)}
            <br />
            <br />
            {card.partial_sale_listing && (
              <Card className='content-card'>
                <h5 style={{ paddingLeft: '7px' }}> {get(primary_agent, 'company.partial_sale_name', 'Partial Sale')} Interest</h5>
                <hr style={{ margin: '10px 0' }} />
              {
                card.partial_sale_listing.conversations.map( convo => <div>
                  <h5 style={{padding: 6}}>
                    {convo.participant2.name} 
                    <span style={{float: 'right'}}>
                      <ConversationContainer client={get(this.props, 'result.client', {})} convoId={convo.id} /> &nbsp;
                    </span>
                  </h5>
                  <hr />
                </div>)
              }
            </Card>)}
          </div>
          <div className={classes.tabContainer}>    
            <InventoryUserSearchField
              label="Agents"
              isMulti
              value={inventoryUsers}
              onChange={v => {
                const crd = { ...card, inventory_users: v };
                this.onSave({ ...card, inventory_users: v.map(i => i.id) });
                updateCard(crd);
                this.setState({ card: crd });
              }}
            />
            <PipelineTaskTab pipelineCardId={card.id} />
            {(pipelineName !== 'Business Continuity Builder' && get(card, 'primary_agent.cat_plan_filename', false) ) && <DownloadCatPlanButton inventoryUser={get(card, 'primary_agent')} />}
            <br/>
            <div>
              <h5>Valuations (DCF)</h5>
              {
                  <div>
                    <table style={{width: '100%'}}>
                      <tr>
                        <th>Name</th>
                        <th>Author</th>
                        <th>Created At</th>
                        <th>Link</th>
                      </tr>
                      {get(card, 'primary_agent.discounted_cash_flows', []).slice().reverse().map(dcf => (
                        <tr>
                          <td>{get(dcf, 'name')}</td>
                          <td>{get(dcf, 'author.name')}</td>
                          <td>{moment(dcf.createdAt).format("D MMM, YYYY")}</td>
                          <td><a href={`/valuations/dcf?dcf_id=${dcf.friendlyName}`} target="_blank">View</a></td>
                        </tr>
                      ))}
                    </table>
                  </div>
              }
            </div>
            <br />
            <CashFlowAnalysisWidgit inventoryUser={pipelineCard.inventory_users[0]} />
            {(pipelineName !== 'Business Continuity Builder' && get(card, 'primary_agent.cat_plan_filename', false) ) && <DownloadCatPlanButton inventoryUser={get(card, 'primary_agent')} />}
            <br/>
            <FileTab variables={{ pipeline_card_id: (card || {}).id }} documents={pipelineCard.documents} />
          </div>
          {this.renderAdditionalTabInfo(pipelineName)}
          {customerUserWorkingDocs && pipelineCardOwner &&
            <FileTab 
              variables={{ inventory_user_id: inventoryUser.id, document_type: workingDocEnumNumber, documents: documents }} 
              document_type
              documents={documents} 
              disablePopup={true} 
            />
          }
          {pipelineName === 'Deal Tracking' && <FileTab variables={{ deal_interest_id: get(pipelineCard, 'deal_interest.id') }} documents={get(pipelineCard, 'deal_interest.documents')} />}
        </SwipeableViews>
      </Drawer>
    );
  }
}

const styles = {
  drawerPaper  : {
    width        : 530,
    height       : 'calc(100% - 100px)',
    position     : 'absolute',
    top          : 68,
    borderRadius : 4,
    overflowY    : 'auto',
    boxShadow    : '-2px 2px 2px -1px #aaa',
    borderTop    : '1px solid #aaa',
  },
  container    : {
    padding : 8,
  },
  tabContainer : {
    minHeight : '80vh',
    padding   : 8,
  },
  dark         : {
    background : '#fafafa',
  },
  inputField   : {
    padding : 8,
  },
  subHeader    : {
    fontSize : 18,
    position : 'relative',
    top      : 4,
  },
  avatar       : { float: 'left', marginRight: 12, height: 64, width: 64 },
  cardHeader   : { fontWeight: 'bold', top: 6, position: 'relative' },
  tabStyle     : {
    minWidth : 50,
    padding  : 0,
  },
};

PipelineCardDrawer.propTypes = {
  onClose      : PropTypes.func.isRequired,
  classes      : PropTypes.object.isRequired,
  updateCard   : PropTypes.func.isRequired,
  mutate       : PropTypes.func.isRequired,
  pipelineList : PropTypes.array,
  pipelineCard : PropTypes.object,
};

PipelineCardDrawer.defaultProps = {
  pipelineList : [],
  pipelineCard : {},
};

export default graphql(updatePipelineCardMutation)(withStyles(styles)(PipelineCardDrawer));
