import React, { useState } from 'react';
import { TableRow, TableCell, Button } from '@material-ui/core';
import { Check, Clear, Save, Edit } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { get } from 'lodash';
import client from '../../lib/apolloClient';

const CREATE_TEAM_REQUEST_ITEM = gql`
  mutation CreateTeamRequestItem($teamRequestId: ID!, $email: String!) {
    createTeamRequestItem(teamRequestId: $teamRequestId, email: $email) {
      id
      email
      approved
       user {
        id
        name
        gross_production
        assets_under_management
        number_of_clients
      }
    }
  }
`;

const NewTeamRequestItem = ({ teamRequest, updateTeamRequestItems, classes }) => {
  const [createTeamRequestItem] = useMutation(CREATE_TEAM_REQUEST_ITEM, { client });
  const [email, setEmail] = useState('');
  const [creating, setCreating] = useState(false);

  const isDuplicate = [teamRequest.items || []].some(item => item.email === email);

  if (creating) {
    return (
      <React.Fragment>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>
            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
          </TableCell>
          <TableCell colSpan={4}>
            <Button
              disabled={email.length === 0} 
              onClick={() => {
              createTeamRequestItem({
                variables: {
                  teamRequestId: teamRequest.id,
                  email,
                },
              }).then(result => {
                setCreating(false);
                updateTeamRequestItems(result.data.createTeamRequestItem);
                setEmail('');
              });
            }}><Save /></Button>
            <Button onClick={() => setCreating(false)}><Clear/></Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          <TableCell colSpan={4}>
            {isDuplicate && <span className={classes.error}>This email is already in the list</span>}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <TableRow>
      <TableCell colspan={4}>
        <Button onClick={() => setCreating(true)}><Edit /> Add </Button>
      </TableCell>
    </TableRow>
  );
}

export default withStyles({
  root: {
    '&:hover': {
      cursor: 'pointer',
    },
    error: {
      color: '#db5856',
    },
  },
})(NewTeamRequestItem);