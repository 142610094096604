import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { get, find } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import * as successionReadinessActionCreators from '../../actions/successionReadinessActionCreators';
import { Table, TableHead, TableBody, TableRow, TableCell, Checkbox, CircularProgress, Typography } from '@material-ui/core';
import moment from 'moment';
import ActivityCard from './ActivityCards/ActivityCard';
import { yearsFromRetirementOptions } from './SuccessionReadinessBanner';
import { FormattedMessage } from 'react-intl';

const ToggleReadinessActivityMutation = gql`
  mutation ToggleReadinessActivity($id: ID!, $status: Boolean!) {
    toggleReadinessActivity(id: $id, status: $status) {
      id
      key 
      title
      text 
      score 
      completed
      category
      dueDate
      ctaTarget
      ctaText
      ctaType
    }
  }
`;


const SuccessionActivityBanner = ({classes, successionReadiness, refetchFunc, setOpen}) => {
  // useMutaion hook
  const [toggleReadinessActivity] = useMutation(ToggleReadinessActivityMutation);
  const [selectedActivity, setSelectedActivity] = React.useState(null);

  const onCardClose = () => {
    setSelectedActivity(null);
  }

  const rowClicked = (activity) => {
    console.log('row clicked', activity);
    setSelectedActivity(activity);
  }

  const readinessActivities = get(successionReadiness, 'readinessActivities', []);
  const completedActivities = readinessActivities.filter(activity => activity.completed === true);

  return (
    <div>
      <ActivityCard isOpen={Boolean(selectedActivity)} onClose={onCardClose} activity={selectedActivity} />
      <h2> 
        To-Do List
      </h2>
      <div className={classes.header}>
        <div className={classes.completion}>
          <span>Based On Your Succession Readiness Assessment Completed On
            <br/>
            <b>
              {moment(get(successionReadiness, 'completionDate')).format("MMM, D, YYYY")}
            </b>
            &nbsp;your goal is to retire in&nbsp;
            <b>{find(yearsFromRetirementOptions, ['value', get(successionReadiness, 'yearsFromRetirement', 0) * 10] ).label}</b>.<br/>
            Below are the key tasks to reach your goal.
          </span>
          <div>&nbsp;</div>
          
        </div>
        <div className={classes.scoreBox}>
          <CircularProgress
            className={classes.progress}
            variant="determinate"
            color="#dadada"
            value={100} // Convert to percentage
            size={100}
            style={{position: 'absolute', top: 0, zIndex: 1, color: '#dadada'}}
          />
          <CircularProgress
            className={classes.progress}
            variant="determinate"
            value={(completedActivities.length / readinessActivities.length) * 100} // Convert to percentage
            size={100}
            sx={{
              color: (theme) =>
                theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
            }}
            style={{zIndex: 2}}
          />
          
          
          <Typography
            variant="h6"
            component="div"
            color="textSecondary"
            className={classes.percentage}
          >
            {`${completedActivities.length} of ${readinessActivities.length}`}
          </Typography>
        </div>
      </div>
      <div className={classes.container}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.status}>Status</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell className={classes.dueDate}>Due Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {readinessActivities.map((item, index) => (
              <TableRow key={index}>
                <TableCell className={[classes.cell, classes.status].join(', ')}>
                  <Checkbox
                    checked={item.completed === true}
                    onClick={() => {
                      toggleReadinessActivity({
                        variables: {
                          id: item.id,
                          status: !item.completed
                        },
                      }).then(() => {
                        // Refetch the data
                        refetchFunc({});
                      });
                      console.log('clicked');
                    }} />
                </TableCell>
                <TableCell 
                  className={[classes.cell, classes.text].join(', ')}
                  onClick={() => rowClicked(item)}>{item.title}
                </TableCell>
                <TableCell 
                  className={[classes.cell, classes.text].join(', ')}
                  onClick={() => rowClicked(item)}>{item.category}
                </TableCell>
                <TableCell 
                  className={[classes.cell, classes.dueDate].join(', ')}
                  onClick={() => rowClicked(item)}>
                  {moment(item.dueDate).format('MMMM DD, YYYY')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className={classes.buttonBox}>
        <a 
          className="btn btn-default"
          style={{cursor: 'pointer'}}
          target="_blank"
          href="/succession_readiness.pdf">Print Assessment</a>
        &nbsp;&nbsp;
        <a 
          className="btn btn-default"
          style={{cursor: 'pointer'}}
          onClick={() => {setOpen(true); setTimeout(() => {setOpen(false);}, 1)}}>Review Assessment</a>
        &nbsp;&nbsp;
        <a 
          className="btn btn-default"
          style={{cursor: 'pointer'}}
          target="_blank" 
          href="/media/contact">
            Schedule a Consultation
        </a>
      </div>
    </div>
  );
}

SuccessionActivityBanner.propTypes = {
  successionReadiness: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  return {
    successionReadiness: get(state, 'successionReadiness.successionReadiness', []),
    refetchFunc: get(state, 'successionReadiness.refetch', () => {}),
  }
};

const styles = theme => ({
  container: {
    maxWidth: '590px',
    maxHeight: '300px', // Setting max height to contain the table
    overflow: 'auto', // Enabling vertical scrolling if necessary
  },
  table: {
    width: '100%',
    tableLayout: 'fixed', // Ensures cells have equal width
    overflowWrap: 'break-word', // Wrap long words
  },
  tableBody: {
    // Remove maxHeight and overflowY from tableBody
  },
  cell: {
    margin: '4px',
    padding: '4px',
    wordWrap: 'break-word',
  },
  status: {
    width: '80px',
  },
  dueDate: {
    width: '150px',
  },
  text: {
    padding: '0px 4px 0px 0px',
  },
  scoreBox: {
    position: 'relative', 
    width: '100px', 
    height: '100px', 
    margin: '10px',
  },
  score: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    paddingRight: '30px'
  },
  completion: {
    fontSize: '16px',
    padding: 8,
  },
  buttonBox: {
    display: 'flex',
    padding: '16px 2px',
  },
  progress: {
    margin: 0,
    position: 'relative',
    width: '100px',
    height: '100px',
  },
  percentage: {
    position: 'absolute',
    top: '34%',
    left: '26%',
  },
});


export default withStyles(styles)(connect(mapStateToProps, {...successionReadinessActionCreators})(SuccessionActivityBanner));
