import React from 'react';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from "@material-ui/core/StepLabel";
import * as cfaActionCreators from "../../../actions/cfaActionCreators";
import {Box, Grid, Paper} from "@material-ui/core";
import StepButton from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CfaList from "../CfaList";
import SaveCfaButton from "../SaveCfaButton";
import SellerInfo from "./SellerInfo";
import NumberOfBuyers from "./NumberOfBuyers";
import { get } from 'lodash';
import Buyer from "./Buyer";
import DownPayment from "./DownPayment";
import FixedPayment from "./FixedPayment";
import ContinuingPayments from "./ContinuingPayments";
import Disclaimer from "./Disclaimer";
import DealStructure from "./DealStructure";
import {FormattedMessage, injectIntl} from "react-intl";

const STEP_LENGTH = 8;

const CfaWizard = ({updateCfaQuery, query, classes, intl, company}) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const currentStepRef = React.useRef();
    const cashFlowOrdinaryIncomeTaxRates = get(company, 'features', []).includes('cash_flow_ordinary_income_tax_rates');
    const cashFlowAnalysisWord = get(company, 'company.data.cash_flow_analysis_word');

    const steps = [
        intl.formatMessage({id: 'calculators.cfa.participants'}),
        intl.formatMessage({id: 'calculators.cfa.terms'}),
        intl.formatMessage({id: 'calculators.cfa.publish'}),
    ];

    const totalSteps = () => {
        return STEP_LENGTH;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };
    const handleNext = () => {
        let newActiveStep = activeStep + 1;

        if (!cashFlowOrdinaryIncomeTaxRates && newActiveStep === 2) {
            newActiveStep += 1;
        }

        if (isLastStep() && !allStepsCompleted()) {
            newActiveStep = steps.findIndex((step, i) => !(i in completed));
        }

        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        let newActiveStep = activeStep - 1;

        if (!cashFlowOrdinaryIncomeTaxRates && newActiveStep === 2) {
            newActiveStep -= 1;
        }

        setActiveStep(newActiveStep);
    };
    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    const currentStep = step => {
        switch(step) {
            case 1:
                return NumberOfBuyers;
            case 2:
                return Buyer;
            case 3:
                return DealStructure;
            case 4:
                return DownPayment;
            case 5:
                return FixedPayment;
            case 6:
                return ContinuingPayments;
            case 7:
                return Disclaimer;
            default:
                return SellerInfo;
        };
    }

    const activeStepToDisplay = () => {
        if(activeStep < 3) {
            return 0
        }
        if(activeStep < 7) {
            return 1
        }
        return 2
    }

    return (
      <React.Fragment>
          <h1><FormattedMessage id="calculators.cfa.title" values={{cash_flow_analysis_word:  cashFlowAnalysisWord}}/></h1>
            <Grid container xs={12} spacing={1}>
                <Grid xs={9}>
                    <Paper className={classes.content}>
                        <Stepper nonLinear activeStep={activeStepToDisplay()}>
                            {steps.map((label, index) => (
                                <Step key={label} completed={index == activeStepToDisplay() - 1}>
                                    <StepButton color="inherit" onClick={handleStep(index)}>
                                        {label}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            <React.Fragment>
                                <Grid>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} className={classes.content}>
                                        {React.createElement(currentStep(activeStep) )}
                                    </Box>
                                </Grid>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <div style={{margin: 10}}>
                                        <Button
                                            variant="contained"
                                            color="inherit"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}>
                                            <FormattedMessage id="calculators.cfa.back"/>
                                        </Button>
                                    </div>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    {activeStep != totalSteps() - 1 ?
                                        <Button disabled={!query.valid} variant="contained" onClick={handleComplete}>
                                            <FormattedMessage id="calculators.cfa.next"/>
                                        </Button>
                                         :
                                        <div style={{margin: 10}}><SaveCfaButton
                                          buttonTextCode="calculators.cfa.generate"
                                          callOnSave={id => {
                                            window.location.href = `/calculators/cfa/${id}`;
                                        }}/>&nbsp;</div>
                                    }
                                    &nbsp;
                                </Box>
                            </React.Fragment>
                        </div>

                    </Paper>
                </Grid>
                <Grid xs={1}></Grid>
                <Grid xs={2}>
                    <CfaList />
                </Grid>
            </Grid>
      </React.Fragment>
    );
}

const mapStateToProps = (state, props) => {
    return {
        result: {...state.cfa.result},
        query: {...state.cfa.query},
        company: state.company,
    };
};

const styles = theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginRight: theme.spacing.unit,
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    content: {
        margin: 4,
        padding: 8,
    },
});

export default connect(mapStateToProps, { ...cfaActionCreators, })(
    withStyles(styles)(injectIntl(CfaWizard))
);
