import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themes from '../lib/themes';
import client from '../lib/apolloClient';
import routes from '../routes/findBobRoutes';
import configureStore from '../store/findBobPublicStore';
import translations from '../lib/i18n/translations.json';
import { IntlProvider } from 'react-intl';
import { Provider, updateIntl } from 'react-intl-redux';
import moment from 'moment';
import { Bling as GPT } from 'react-gpt';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { toggleHiddenForUsers } from '../components/InventoryUsers/InventoryUserQueries';

const generateClassName = createGenerateClassName();
const jss = create(jssPreset());

GPT.syncCorrelator();
GPT.enableSingleRequest();

const FindBobApp = (props, railsContext) => {
  const store = configureStore({ ...props, company: { 
    host: railsContext.company_host,
    features: railsContext.features,
    file_import_disclaimer: railsContext.file_import_disclaimer,
    name: railsContext.company_name,
    branded_name: railsContext.company_branded_name,
    short_name: railsContext.company_short_name,
  } });
  document.store = store;

  // set locale and messages for IntlProvider.
  // TODO: Add a method that checks for current locale
  const locale = railsContext.i18nLocale || 'en';
  
  window.locale = locale;
  
  moment.locale(locale);

  let theme = themes[railsContext.theme_name];

  store.dispatch(
    updateIntl({
      locale,
      messages: translations[locale],
    })
  );
  return (<div data-turbo="false">
      <JssProvider
        jss={jss}
        generateClassName={generateClassName}
        classNamePrefix="fbFront-">
        <MuiThemeProvider theme={createMuiTheme(theme)}>
          <CssBaseline />
          <Provider store={store} props>
              <SnackbarProvider>
                <ApolloProvider client={client}>
                  <BrowserRouter>{routes}</BrowserRouter>
                </ApolloProvider>
              </SnackbarProvider>
          </Provider>
        </MuiThemeProvider>
      </JssProvider>
    </div>
  );
};

export default FindBobApp;
