import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { assign, get } from 'lodash';
import { FormControlLabel, TableRow, TableCell, Table } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import client from '../../lib/apolloClient';
import numeral from 'numeral';


const DELETE_TEAM_MEMBER = gql`
  mutation RemoveTeamMember($id: ID!, $sendEmail: Boolean) {
    removeTeamMember(id: $id, sendEmail: $sendEmail) {
      id
    }
  }
`;

const TeamMemberRow = ({ teamMember, updateTeamMembers, isUser }) => {
  const [deleteTeamMember] = useMutation(DELETE_TEAM_MEMBER, { client });

  return (
    <TableRow>
      <TableCell>{teamMember?.user?.name}</TableCell>
      <TableCell>{numeral(teamMember?.user?.original_gross_production).format('$0,0')}</TableCell>
      <TableCell>{numeral(teamMember?.user?.original_assets_under_management).format('$0,0')}</TableCell>
      <TableCell>{teamMember?.user?.original_number_of_clients}</TableCell>
      <TableCell>
        {!isUser &&
          <FormControlLabel
            control={
              <Delete
                style={{ color: '#e8a2a2' }}
                onClick={(e) => {
                  e.stopPropagation();
                  const confirmed = confirm('Are you sure you want to remove this team member?');
                  if(!confirmed) return;
                  const sendEmail = confirm('Great, Would you like to send an email to the user notifying them of the change?');

                  deleteTeamMember({
                    variables: {
                      id: teamMember.id,
                      sendEmail: sendEmail
                    }
                  }).then(() => {
                    updateTeamMembers();
                  });
                }}
              />
            }
          />}
      </TableCell>
    </TableRow>
  );
}

export default TeamMemberRow;